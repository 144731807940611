/** @jsx jsx */
import { jsx, Spinner } from "theme-ui";
import PropTypes from "prop-types";

import {
  doFormatShortDate,
  doFormatTitle,
} from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { ActionButton, HoverCard, HoverCardType } from "office-ui-fabric-react";
import SearchPreceptor from "gatsby-plugin-hfn-profile/components/SrcmModels/SearchPreceptor";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import ZoneAndZCEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/ZoneAndZCEntity";

import StreamingInput from "gatsby-plugin-hfn-profile/components/StreamingInput";
import {
  abhyasiIdRegex,
  validateAbhyasiId,
  validateEmail,
  validatePhoneNumberMobile,
} from "sites-common/utils/validations";
import { useCallback, useState } from "react";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import RenderTableData from "sites-common/components/RenderTableData";
import { has } from "sites-common/utils/lodash";

const RenderPreceptorStartDate = (abhyasiId) => (
  <div sx={{ bg: "#c0392b", color: "white", p: 2 }}>
    <SearchPreceptor
      model="preceptors"
      abhyasiId={abhyasiId}
      render={(p) => (
        <div>
          <img width="150" src={p.photo_url} alt="" />
          <div sx={{ fontSize: "1.2em", fontWeight: "700" }}>
            {doFormatTitle(p.name)}
          </div>
          <div>
            <font sx={{ fontWeight: "300" }}>prefect status:</font>{" "}
            {p.prefect_status}
          </div>
          <div>
            <font sx={{ fontWeight: "300" }}>sub status:</font>{" "}
            {p.prefect_sub_status}
          </div>
          <div>
            <font sx={{ fontWeight: "300" }}>prefect start date:</font>{" "}
            {doFormatShortDate(p.prefect_start_date)}
          </div>
          {p.prefect_end_date && (
            <div>
              <font sx={{ fontWeight: "300" }}>prefect end date:</font>{" "}
              {doFormatShortDate(p.prefect_end_date)}
            </div>
          )}
        </div>
      )}
    />
  </div>
);

const PreceptorDetails = ({ abhyasiId, display }) => {
  const plainCardProps = {
    onRenderPlainCard: RenderPreceptorStartDate,
    renderData: abhyasiId,
  };

  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick
      type={HoverCardType.plain}
    >
      <ActionButton iconProps={{ iconName: "Photo" }} text={display} />
    </HoverCard>
  );
};

PreceptorDetails.propTypes = {
  abhyasiId: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
};

const rloading = (x) =>
  x.loading ? (
    <Spinner />
  ) : (
    <div sx={{ color: "white", bg: "red" }}> {x.error}</div>
  );
const colDefns = [
  ["Query", "key"],
  [
    "Name",
    (r) => (r.abhyas_stage ? r.name : rloading(r)),
    // (r) =>   (
    //   <AbhyasiEntity
    //     abhyasiId={r.ref}
    //     display={doFormatTitle(r.name)}
    //     item={r}
    //     showPhoto
    //   />
    // ),
  ],
  ["Age", (r) => r.age || ""],
  ["Gender", (r) => r.gender || ""],
  [
    "City",
    (r) =>
      r.abhyas_stage ? (
        <div>
          {doFormatTitle(r.city)}{" "}
          <div sx={{ fontSize: "0.8em", opacity: 0.9 }}>
            {r.state ? r.state.name : ""} {r.country ? r.country.name : ""}
          </div>
        </div>
      ) : (
        ""
      ),
  ],
  [
    "Abhyasi Id",
    (r) =>
      r.abhyas_stage ? (
        <AbhyasiEntity abhyasiId={r.ref} item={r} />
      ) : (
        <div sx={{ p: 1, bg: r.loading ? "darkblue" : "red", color: "white" }}>
          {" "}
          {r.ref}{" "}
        </div>
      ),
  ],
  [
    "Abhyas Stage",
    (r) =>
      r.abhyas_stage ? (
        <div>
          {r.is_prefect &&
            (r.abhyas_stage !== "TRAINER" ? (
              <div>
                {r.abhyas_stage}
                <br />
                <PreceptorDetails
                  abhyasiId={r.ref}
                  display="TRAINER_INACTIVE"
                />
              </div>
            ) : (
              <PreceptorDetails abhyasiId={r.ref} display={r.abhyas_stage} />
            ))}
          {!r.is_prefect && r.abhyas_stage}
        </div>
      ) : (
        ""
      ),
  ],
  [
    "Date of Joining",
    (r) => (r.date_of_joining ? doFormatShortDate(r.date_of_joining) : ""),
  ],
  [
    "Center",
    (r) =>
      r.srcm_group ? (
        <ZoneAndZCEntity
          centerId={r.srcm_group.id}
          display={r.srcm_group.name}
        />
      ) : (
        ""
      ),
  ],
];

const MeSearch = () => {
  const { fetchSrcmApi } = useFetchSrcmApi();

  const [fetched, setFetched] = useState({});

  const fetchAndStore = useCallback(
    (value) => {
      let key = null;
      let val = null;
      if (value) {
        if (validateAbhyasiId(value.toUpperCase())) {
          [key, val] = ["ref", value.toUpperCase()];
        } else if (validatePhoneNumberMobile(value)) {
          [key, val] = [
            "mobile",
            value.substring(0, 1) !== "+" && value.length === 10
              ? `+91${value}`
              : value,
          ];
        } else if (validateEmail(value.toLowerCase())) {
          [key, val] = ["email", value.toLowerCase()];
        }
      }

      const setF = (X) =>
        setFetched((prevState) => ({ ...prevState, [val]: X }));
      const resetF = (X) =>
        setFetched((prevState) => {
          const p = { ...prevState };
          delete p[val];
          return {
            ...p,
            [X.id]: { key: val + (X.id in p ? `, ${p[X.id].key}` : ""), ...X },
          };
        });

      setF({ key: val, loading: true });

      fetchSrcmApi({
        api: `/api/v2/abhyasis/`,
        client: "preceptorCandidateAppClient",
        methodParams: {
          [key]: val,
        },
      })
        .then((res) => {
          if (has(res, ["results", 0, "abhyas_stage"])) {
            resetF(res.results[0]);
          } else {
            setF({
              key: val,
              error: res.detail || `${key} ${val} Not Found`,
            });
          }
        })
        .catch((error) => {
          setF({ key: val, error: error.toString() });
        });
    },
    [fetchSrcmApi]
  );

  return (
    <div sx={{ p: 4 }}>
      <div>
        Enter Abhyasi IDs / Email IDs (seperated by a space or a new-line)
      </div>
      <StreamingInput
        fixed_patterns={[abhyasiIdRegex, "[1-9][0-9]{9}"]}
        sep_patterns={["[^\\s]+@[^\\s]+\\.[^\\s]+", "^[+][0-9]+"]}
        sep={[" ", ",", ";"]}
        fieldProps={{ autoFocus: false, multiline: true, rows: 5 }}
        onNewValue={fetchAndStore}
      />
      {Object.keys(fetched).length > 0 && (
        <div sx={{ fontSize: "0.8em", mb: 2 }}>
          <i>
            Search Results will get appended below. Refresh page to clear
            results!
          </i>
        </div>
      )}
      <div sx={{ m: 2 }}>
        <RenderTableData
          showHeader
          colDefns={colDefns}
          rowData={Object.values(fetched)}
        />
      </div>
    </div>
  );
};

export default MeSearch;
