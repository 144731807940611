/** @jsx jsx */
import { jsx } from "theme-ui";
import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";

const StreamingInput = ({
  fixed_patterns,
  sep_patterns,
  sep,
  fieldProps,
  onNewValue,
}) => {
  const [values, setValues] = useState("");
  const [, setFetched] = useState({});

  const [fixedPatternsRegex, sepPatternsRegex, sepRegexNL, sepPlus] =
    useMemo(() => {
      const x = fixed_patterns.map(
        (p) => `(${p.replace(/^\^*/g, "").replace(/\$*$/g, "")})`
      );
      const y = sep_patterns.map(
        (p) => `(${p.replace(/^\^*/g, "").replace(/\$*$/g, "")})`
      );

      // const seps = `[${sep.join("")}]`;
      const sepsNL = `[${sep.join("")}\n\r]`;
      // console.log("SEPSNL", sepsNL);
      // const patternRegex1 = new RegExp(
      //   [...x, ...y].map((z) => `(${z})`).join("|"),
      //   "gm"
      // );
      const fixedPatternsRegex1 = !fixed_patterns
        ? null
        : new RegExp(`^(${x.map((z) => `(${z})`).join("|")})$`, "");
      const sepPatternsRegex1 = !sep_patterns
        ? null
        : new RegExp(`^(${y.map((z) => `(${z})`).join("|")})$`, "");
      // const linePatternsRegex1 = new RegExp(
      //   `^(${[...x, ...y].map((z) => `(${z})`).join("|")})$`,
      //   "gm"
      // );
      const sepRegexNL1 = new RegExp(sepsNL, "g");
      const sepPlus1 = new RegExp(`(${sepsNL})+`, "gm");
      return [fixedPatternsRegex1, sepPatternsRegex1, sepRegexNL1, sepPlus1];
    }, [fixed_patterns, sep_patterns, sep]);

  const parseAndFetch = useCallback(
    (v) => {
      let y = JSON.parse(JSON.stringify(v));
      const lines = v.split(sepRegexNL);

      // console.log("HELLO", v, lines, lines.length);

      const identifiedValues = {};

      lines.forEach((line, idx) => {
        if (line) {
          const notLastLine = idx + 1 !== lines.length;
          // console.log("LI", line, idx, lines.length);
          if (fixedPatternsRegex.test(String(line))) {
            // console.log("MATCH", line);
            y = y.replaceAll(line, "");
            identifiedValues[line] = true;
          } else if (notLastLine && sepPatternsRegex.test(String(line))) {
            // console.log("MATCH", line);
            y = y.replaceAll(line, "");
            identifiedValues[line] = true;
          }
        }
      });

      if (identifiedValues) {
        setFetched((prevState) => {
          const newState = { ...prevState };
          // console.log("STATE", newState);

          Object.keys(identifiedValues).forEach((line) => {
            if (!(line in prevState)) {
              newState[line] = true;
              onNewValue(line);
            }
          });
          return newState;
        });
      }
      if (y !== v) {
        setValues(y.replaceAll(sepPlus, "\n").trimStart());
      } else {
        setValues(v);
      }
    },
    [sepPatternsRegex, fixedPatternsRegex, sepRegexNL, sepPlus, onNewValue]
  );
  // console.log("VALUES", values, values.length);
  const onBlur = useCallback(
    () => parseAndFetch(`${values}\n`),
    [parseAndFetch, values]
  );
  return (
    <DynamicField
      name="pois"
      label=""
      props={{
        required: false,
        placeholder: "Strings seperated by a comma or a space",
        ...fieldProps,
        onBlur,
      }}
      type="text"
      value={values}
      onChange={parseAndFetch}
    />
  );
};

StreamingInput.propTypes = {
  fixed_patterns: PropTypes.arrayOf(PropTypes.string),
  sep_patterns: PropTypes.arrayOf(PropTypes.string),
  sep: PropTypes.arrayOf(PropTypes.string),
  fieldProps: PropTypes.shape({}),
  onNewValue: PropTypes.func.isRequired,
};
StreamingInput.defaultProps = {
  fieldProps: {},
  sep: ["\\s", ","],
  sep_patterns: ["[^\\s]+"],
  fixed_patterns: [],
};

export default StreamingInput;
